import { useState } from "react";

const Checkbox = ({ checked, onChange = () => {}, id }) => {
	const [isChecked, setIsChecked] = useState(checked);

	return (
		<label className="checkbox" htmlFor={id}>
			<input
				id={id}
				type="checkbox"
				title={"Checkbox " + id}
				checked={isChecked}
				onChange={(e) => {
					setIsChecked(e.target.checked);
					onChange(e.target.checked);
				}}
			/>
			<span className="slider"></span>
		</label>
	);
};

export default Checkbox;
