import axios from "axios";
import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import { getAPI } from "../../data/config";
import Popup from "../layout/Popup";

const EditSelfPopup = ({ close, user }) => {
	const [vorname, setVorname] = useState(user.vorname);
	const [nachname, setNachname] = useState(user.nachname);
	const [email, setEmail] = useState(user.email);

	const userc = useContext(UserContext).user;

	function updateUser(vorname, nachname, email) {
		axios
			.post(getAPI() + "actions/edit.php", {
				action: "user",
				id: userc.token,
				vorname,
				nachname,
				email,
			})
			.then(() => {
				close();
			});
	}

	return (
		<Popup close={close}>
			<h3>Profil bearbeiten</h3>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					updateUser(vorname, nachname, email);
				}}>
				<input
					type="text"
					name="vorname"
					id="vorname"
					placeholder="Vorname"
					value={vorname}
					autoComplete="new-vorname"
					onChange={(e) => {
						setVorname(e.target.value);
					}}
				/>

				<input
					type="text"
					name="nachname"
					id="nachname"
					placeholder="Nachname"
					value={nachname}
					autoComplete="new-nachname"
					onChange={(e) => {
						setNachname(e.target.value);
					}}
				/>

				<input
					type="text"
					name="mail"
					id="mail"
					placeholder="E-Mail"
					value={email}
					autoComplete="current-email"
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				/>

				<button>Aktualisieren</button>
			</form>
		</Popup>
	);
};

export default EditSelfPopup;
