import React from "react";
import { NavLink } from "react-router-dom";

import { getMenu } from "../../data/MenuData";

const Sidebar = () => {
	let menu = getMenu();

	return (
		<aside className="sidebar">
			<nav>
				<ul>
					{menu.map((entry) => {
						return (
							<li key={entry.url}>
								<NavLink
									to={entry.url}
									onClick={() => {
										document
											.querySelector("body")
											.classList.remove(
												"sidebar--mobile"
											);
									}}>
									{entry.icon}
									<span>{entry.name}</span>
								</NavLink>
							</li>
						);
					})}
				</ul>
			</nav>

			<footer>© Constantin Birken {new Date().getFullYear()}</footer>
		</aside>
	);
};

export default Sidebar;
