import React, { useState } from "react";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

// Nach einem Tutorial von Ben Awad auf YouTube

const useInputValue = (initialValue) => {
	const [value, setValue] = useState(initialValue);

	return {
		value,
		onChange: (e) => setValue(e.target.value),
		resetValue: () => setValue(""),
	};
};

const Textfeld = ({ onSubmit, placeholder, typ = "standard", max = 2048 }) => {
	const { resetValue, ...text } = useInputValue("");

	const { resetValue: resetZahl, ...zahl } = useInputValue();

	return (
		<form
			className="textfeld"
			onSubmit={(e) => {
				e.preventDefault();
				if (text.value !== "") {
					if (typ === "zahl") {
						onSubmit(text.value, zahl.value);
						resetZahl();
					} else {
						onSubmit(text.value);
					}
					resetValue();
				}
			}}>
			<input placeholder={placeholder} maxLength={max} {...text} />

			{typ === "zahl" ? (
				<input
					type="number"
					placeholder="Betrag"
					{...zahl}
					max="100000"
					min="1"
				/>
			) : (
				<></>
			)}

			<button title="Senden">
				<SendRoundedIcon />
			</button>
		</form>
	);
};

export default Textfeld;
